.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: rgba(0,0,0, 0.6);
    opacity: 1;
    transform: scale(1, 0);
    transform-origin: left bottom;
    transition: transform 0.325s ease-in-out;
}

.overlay--active {
    transform: scale(1, 1);
    transition: transform 0.325s ease-in-out;
}

