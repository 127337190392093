.video-wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%;

    > video,
    .video-js {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }
}