.page-hero,
.page-hero-static {  
    position: relative; 
    height: max(calc(100vw * 0.42968), 490px);
    overflow: hidden;
}

.page-hero-static {
    height: auto;
    min-height: 520px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
}

.page-hero__content {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
}

.page-hero__stats {
    position: absolute;
    bottom: 1.75rem;
    display: flex;
    justify-content: space-between;
    width: fit-content;
    list-style: none;
    margin: 3rem 0 0;
    padding: 0;
}

.page-hero__stat {
    flex: 0 1 fit-content;
    padding-right: 2rem;
    box-sizing: border-box;
    
    .stat-item__value {
        font-size: 3.2rem;
    }

    .stat-item__label {
        font-size: 1rem;
        padding-top: 0.3rem;
        background-image: none;

    }

    .stat-item {
        width: fit-content;
        padding-bottom: 1.5rem;
        background-image: var(--primary-gradient-1);
        background-position: left bottom 1rem;
        background-size: 100% 0.5rem;
        background-repeat: no-repeat;
    }

    .stat-item {
        justify-content: start;
    }

    &:last-child {
        margin-left: 0;
        
        > .stat-item {
            justify-content: start;
        }
    }

    &:first-child {
        > .stat-item {
            justify-content: start;
        }
    }
}

.static-page-hero__content {
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ad-page-hero__content-wrapper {
   margin-top: 2rem;
   padding-bottom: 2rem;
}

h2.ad-page-hero__headline,
h2.dist-page-hero__headline {
    font-size: 4.4rem;
    margin: 11.5rem 0 0;
    font-family: 'Bebas Neue';
    text-align: center;
    letter-spacing: unset;
}

h6.ad-page-hero__headline {
    width: 50%;
    margin: 0 auto 2.8rem;
    padding-bottom: 1.4rem;
    border-bottom: 1px solid;
    text-align: center;
}

.ad-page-hero__brands-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 1.5rem;
    width: fit-content;
    list-style: none;
    padding: 0;
}

.ad-page-hero__brand-item {
    flex: 0 1 4.4rem;
}

.hero-brand-link {
    display: block;
    position: relative;

    img {
        width: 100%;
        transition: opacity 0.325s ease-out;
    }

    &:hover {
       > img {
            opacity: 0;
        }

        .hero-brand-blue-wrapper {
            opacity: 1;
        }
    }
}

.hero-brand-blue-wrapper {
    position: absolute;
    top: -2px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.325s ease-out;
}

.page-hero__img-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: max(calc(100vw * 0.4255), 490px);
    overflow: hidden;    

    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: linear-gradient(to top, rgba(0,0,0, 0.8) 0%, rgba(0,0,0, 0.7) 10%, rgba(0,0,0, 0) 50%);
    }
    
    > img {
        width: 100%;
        object-fit: cover;
    }
}

@media screen and (max-width: 1150px) {
    .page-hero__img-wrap > img {
        width: unset;
        height: 100%;
    }
}

.page-hero-static .page-hero__img-wrap {
    > img {
        object-fit: fill;
    }
}

.dist-hero__img-wrap {
    filter: opacity(0.4);
}

.page-hero__headline {
    position: absolute;
    bottom: 3.5rem;
    font-size: 4.4rem;
    margin: 0;
    font-family: 'Bebas Neue';
    text-align: center;
    letter-spacing: unset;
}

// .page-hero:has(.video-play-btn) .page-hero__headline {
//     bottom: 7.5rem;
// }

.page-hero__subheadline {
    font-size: 1rem;
    font-weight: 500;
    text-transform: none;
    letter-spacing: unset;
    line-height: 1.5;
    text-align: center;
    margin-top: 0.5rem;
    width: 66%;
}

.dist-page-hero__content {
    .page-hero__subheadline {
        line-height: 1.8;
    }
}

.page-hero__logo {
    height: 11rem;
    max-width: 22rem;
    object-fit: contain;
}

.page-hero .video-play-btn {
    position: absolute;
    z-index: 1;
    left: 76%;
    bottom: -1.1rem;
    padding: 5.7rem 3.1rem 3.5rem;   
    color: var(--primary-color-5);
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 700;
    letter-spacing: 4px;
    background-color: rgb(46 100 255 / 48%);
    border: none;
    transition: transform 0.325s ease-in-out, background-color 0.325s ease-out;
    cursor: pointer;
    box-shadow: 0 0 0 117px rgb(46 100 255 / 18%);
    font-family: 'Montserrat';

    &:hover {
        color: var(--primary-gradient-5);
        background-color: rgba(46, 100, 255, 80%);

        .video-play-btn__icon {
            border-color: transparent;
            fill: var(--primary-color-5);
            background-color: rgba(46, 100, 255, 0);
            transform: scale(1.5);
        }

        &:before {
            width: 14rem;
            height: 13rem;
            opacity: 0;
            transition: width 0.25s ease-out, height 0.25s ease-out, opacity 0.15s ease-in 0.1s;
        }
    }

    &:before {
        content: "";
        position: absolute;
        top: 48%;
        left: 49%;
        transform: translate(-50%, -50%);
        border: 2px solid;
        border-radius: 50%;
        background-color: transparent;
        width: 4rem;
        height: 3.7rem;
        padding-top: 0.2rem;
        transition: width 0.25s ease-out, height 0.25s ease-out, opacity 0.15s ease-in;
    }
}

@media screen and (max-width: 955px) {
    .page-hero .video-play-btn {
        left: 70%;
    }
}

@media screen and (max-width: 765px) {
    .page-hero .video-play-btn {
        left: 60%;
    }
}

.page-hero .video-play-btn__icon {
    display: block;
    margin: 0 auto 1.8rem;
    padding: 0;
    border: none;
    border-radius: 50%;
    background-color: rgba(20, 50, 139, 0.5);
    fill: transparent;
    transition: transform 0.325s ease-out, fill 0.325s ease-out;
}

.page-hero--mobile {
    display: none;
}

@media screen and (max-width: 1200px) {
    .ad-page-hero__brands-list {
        flex-wrap: wrap;
        justify-content: center;
    }

    .ad-page-hero__brand-item {
        padding-right: 1rem;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 1160px) {
    .page-hero__logo {
        max-width: 14rem; 
    }
}

@media screen and (max-width: 960px) {
    .page-hero__subheadline {
        width: 88%;
    }
}

@media screen and (max-width: 820px) {
    h2.ad-page-hero__headline {
        font-size: 3.8rem;
    }
}

@media screen and (max-width: 705px) {
    h2.ad-page-hero__headline {
        font-size: 3.125rem;
    }
}

@media screen and (max-width: 768px) {
    .page-hero__subheadline {
        width: 100%;
    }
}


@media screen and (max-width: 600px) {
    .page-hero--mobile { display: block; }

    .page-hero__img-wrap:not(.page-hero--mobile) { display: none; }
    
    .page-hero__content {
        padding: 0 1.5rem;
    }

    .page-hero,
    .page-hero__img-wrap {
        height: min(calc(100vw * 1.89), 800px);
        background-size: auto 100%;
    }

    .page-hero--taller {
        height: min(calc(213vw * 1.89), 800px)
    }

    .page-hero__img-wrap > img { width: 100%; height: 100%; }

    .page-hero__logo {
        max-width: 11rem;
        height: 7.5rem;
    }

    .page-hero__headline {
        bottom: 1.5rem;
    }

    .page-hero:has(.video-play-btn) .page-hero__headline {
        bottom: 19.5rem;
    }

    .page-hero__stats {
        bottom: 8.25rem;
        display: block;
    }

    .page-hero .video-play-btn {
        left: 41%;
    }

    h2.ad-page-hero__headline,
    h2.dist-page-hero__headline {
        font-size: 3.125rem;
    }

    .page-hero__subheadline {
        line-height: 1.8;
        padding: 0 1.5rem;
        width: 90%;
    }

    h6.ad-page-hero__headline {
        width: 80%;
    }

    .ad-page-hero__content-wrapper {
        margin-top: 6rem;
    }

    .ad-page-hero__brands-list {
        width: 90%;
    }

    .ad-page-hero__brand-item {
        flex: 0 1 calc(33% - 3.5rem);
        padding: 0 1.75rem;
        margin-bottom: 3rem;
    }
}

@media screen and (max-width: 375px) {
    .page-hero .video-play-btn {
        left: 39%;
    }
}