.video-card {
    margin: 0;

    .text-link {
        display: block;
        margin-left: -0.75rem;
        width: fit-content;
        white-space: nowrap;
    }

    &:hover .text-link {
        background-size: 100% 100%;
        color: var(--primary-color-5);
    }
}

.video-card__img-wrapper {
    position: relative;
    margin-bottom: 0.8rem;
    cursor: pointer;

    &:hover .video-card__hover-background {
        background-color: rgba(46, 100, 255, 0.4);
        background-size: 100% 100%;
    }

    &:hover .video-play-btn {
        background-color: var(--primary-color-1);
    }

    &:hover .video-play-btn__icon {
        fill: rgba(0,0,0, 0.5);
    }
}

.video-card__img {
    width: 100%;
    aspect-ratio: 3 / 2;
    object-fit: cover;
}

.video-card__hover-background {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    background-image: linear-gradient(to top, rgba(0,0,0, 0.6) 0%, rgba(0,0,0, 0) 33%);
    background-size: 100% 0%;
    background-repeat: no-repeat;
    background-position-y: bottom;
    transition: background-color 0.325s ease-out, background-size 0.225s ease-out 0.1s;
}

.video-card__caption .text-link {
    color: var(--primary-color-1);
    letter-spacing: unset;
}

.video-card__caption .text-link:focus,
.video-card__caption .text-link:hover {
    color: var(--primary-color-5);
}

.video-card__caption .inline-link {
    font-weight: 500;
}

.video-card__caption-lead {
    font-size: 0.7rem;
    font-weight: 900;
    color: var(--primary-color-8);
    text-transform: uppercase;
    margin-bottom: 0.2rem;;
}

.video-card__title {
    font-size: 1.1rem;
    font-weight: 700;
    color: var(--primary-color-4);
    margin-bottom: 1.2rem;
}

.video-card__description {
    margin-top: 0.5rem;
    padding-right: 1rem;
    line-height: 1.5;
}

.video-card__btn {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    color: var(--primary-color-1);
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
}

.video-card .video-play-btn {
    color: var(--primary-color-5);
    transition: all 0.325s ease-out;
    position: absolute;
    z-index: 10;
    right: 1rem;
    bottom: 1rem;

    &:hover {
        transform: scale(1.15);
        transform-origin: center;
    }
}

@media screen and (max-width: 600px) {
    .video-card {
        padding-bottom: 2rem;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid var(--primary-color-1);

        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
        }
    }

    .video-card__caption .text-link {
        background-size: 100% 100%;
        color: var(--primary-color-5);
        margin-left: 0;
    }

    .video-card__title {
        margin-bottom: 0.3rem;
    }
}