.spotlight {
    &.page-section-padding {
        padding-bottom: 6rem;
    }
    
    background-image: var(--primary-gradient-1), linear-gradient(135deg, rgba(76,76,76,1) 0%,rgba(89,89,89,1) 12%,rgba(102,102,102,1) 25%,rgba(71,71,71,1) 39%,rgba(44,44,44,1) 50%,rgba(0,0,0,1) 51%,rgba(17,17,17,1) 60%,rgba(43,43,43,1) 76%,rgba(28,28,28,1) 91%,rgba(19,19,19,1) 100%); 
    background-size: 100% 1rem, cover;
    background-repeat: no-repeat;
    background-position: left bottom, left top;
    background-color: var(--primary-color-4)
}

.spotlight__list {
    --spotlight-item-size: calc(33% - 0.5rem);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem 1rem;
    list-style: none;
    margin: 2rem 0 0;
    padding: 0;
}

.spotlight__item {
    flex: 0 1 var(--spotlight-item-size);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: var(--spotlight-item-size);
    position: relative;
    background-color: rgba(155,155,155, 0.8);  
}

@media screen and (max-width: 1020px) {
    .spotlight__list {
        --spotlight-item-size: calc(50% - 0.8rem);
    }
}

@media screen and (max-width: 600px) {
    .spotlight__list {
        display: block;
        --spotlight-item-size: calc(100% - 2rem);
    }
}

.spotlight__link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0, 0);
    transition: background-color 0.325s ease-out;
    color: var(--primary-color-5);
    padding: 0 1rem;
    cursor: pointer;

    &:hover {
        background-color: rgba(0,0,0, 0.4);

        .text-link {
            background-size: 100% 100%;
            color: var(--primary-color-5);
            text-decoration: none;
            opacity: 1;
        }
    }

    &:active {
        color: var(--primary-color-5);
        
        .text-link {
            background-size: 0 100%;
            border: 1px solid var(--primary-color-1);
            color: var(--primary-color-1);
            text-decoration: none;

            &:before {
                content: "";
                position: absolute;
                top: 9px;
                height: 2px;
                width: 116%;
                transform: rotate(-1.5deg);
                background-color: var(--primary-color-1);
                left: 0;
                box-shadow: none;
            }

            &:after {
                content: "";
                position: absolute;
                bottom: 9px;
                height: 2px;
                width: 116%;
                transform: rotate(1.5deg);
                background-color: var(--primary-color-1);
                left: 0;
            }
        }
    }
}

.spotlight__content {
    position: absolute;
    bottom: 1rem;
    width: calc(100% - 1rem); 
}

.spotlight__category {
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: unset;
    text-transform: none;
    padding: 0.4rem 1.2rem 0.3rem;
    margin-bottom: 0.5rem;
    background-color: var(--primary-color-2);
    width: fit-content;

}

.spotlight__title {
    font-size: 1rem;
    font-weight: 500;
    text-transform: none;
    letter-spacing: unset;
    margin: 0;
    padding-right: 1rem;
}

.spotlight .text-link {
    opacity: 0;
    position: absolute;
    left: 0;
    bottom: -2.4rem;

    &:hover {
        opacity: 1;
    }
} 

@media screen and (max-width: 600px) {
    .spotlight {
        &.page-section-padding {
            padding-bottom: 6rem;
            padding-top: 3rem;
        }
    }

    .spotlight .headline {
        margin-bottom: 4rem;
    }

    .spotlight__item {
        margin-bottom: 3rem;
    }

    .spotlight .text-link {
        opacity: 1;
        background-size: 100% 100%;
        bottom: -1.2rem;
    }

    .spotlight__content {
        bottom: 2.5rem;
    }
}