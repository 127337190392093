.media-link__wrapper {
    &:hover {
        .article-icon:after {
            opacity: 0;
        }

        .article-icon::before {
            opacity: 1;
        }
    }

    .article-icon {
        position: relative;
        padding-left: 1.5rem;
        margin-right: 0.5rem;
        vertical-align: middle;
        
        &:before,
        &:after {
            position: absolute;
            top: -0.4rem;
            left: 0;
            width: 1.3rem;
            height: auto;
            opacity: 1;
            transition: opacity 0.325s ease-out;
        }

        &.article-icon--video:before,
        &.article-icon--video:after {
            top: -0.2rem;
        }

        &:before {
            opacity: 0;
        }
    }
}

.article-icon--pdf {
    &:after {
        content: url(https://85b46b791b5f3b2751f8-e82bfe2fb47c3e0e09486fab21221afc.ssl.cf5.rackcdn.com/scripps/media-type-icons/snn_icon-PDF_OFF.svg);
    }

    &:before {
        content: url(https://85b46b791b5f3b2751f8-e82bfe2fb47c3e0e09486fab21221afc.ssl.cf5.rackcdn.com/scripps/media-type-icons/snn_icon-PDF_OVER.svg);
    }
}

.article-icon--worddoc {
    &:after {
        content: url(https://85b46b791b5f3b2751f8-e82bfe2fb47c3e0e09486fab21221afc.ssl.cf5.rackcdn.com/scripps/media-type-icons/snn_icon-DOC_OFF.svg);
    }

    &:before {
        content: url(https://85b46b791b5f3b2751f8-e82bfe2fb47c3e0e09486fab21221afc.ssl.cf5.rackcdn.com/scripps/media-type-icons/snn_icon-DOC_OVER.svg);
    }
}

.article-icon--pr {
    &:after {
        content: url(https://85b46b791b5f3b2751f8-e82bfe2fb47c3e0e09486fab21221afc.ssl.cf5.rackcdn.com/scripps/media-type-icons/snn_icon-PR_OFF.svg);
    }

    &:before {
        content: url(https://85b46b791b5f3b2751f8-e82bfe2fb47c3e0e09486fab21221afc.ssl.cf5.rackcdn.com/scripps/media-type-icons/snn_icon-PR_OVER.svg);
    }
}

.article-icon--gallery {
    &:after {
        content: url(https://85b46b791b5f3b2751f8-e82bfe2fb47c3e0e09486fab21221afc.ssl.cf5.rackcdn.com/scripps/media-type-icons/snn_icon-PHOTOS_OFF.svg);
    }

    &:before {
        content: url(https://85b46b791b5f3b2751f8-e82bfe2fb47c3e0e09486fab21221afc.ssl.cf5.rackcdn.com/scripps/media-type-icons/snn_icon-PHOTOS_OVER.svg);
    }
}

.article-icon--slideshow {
    &:after {
        content: url(https://85b46b791b5f3b2751f8-e82bfe2fb47c3e0e09486fab21221afc.ssl.cf5.rackcdn.com/scripps/media-type-icons/snn_icon-SLIDESHOW_OFF.svg);
    }

    &:before {
        content: url(https://85b46b791b5f3b2751f8-e82bfe2fb47c3e0e09486fab21221afc.ssl.cf5.rackcdn.com/scripps/media-type-icons/snn_icon-SLIDESHOW_OVER.svg);
    }
}

.article-icon--video {
    &:after {
        content: url(https://85b46b791b5f3b2751f8-e82bfe2fb47c3e0e09486fab21221afc.ssl.cf5.rackcdn.com/scripps/media-type-icons/snn_icon-WATCH_OFF.svg);
    }

    &:before {
        content: url(https://85b46b791b5f3b2751f8-e82bfe2fb47c3e0e09486fab21221afc.ssl.cf5.rackcdn.com/scripps/media-type-icons/snn_icon-WATCH_OVER.svg);
    }
}

.article-icon--default {
    &:after {
        content: url(https://85b46b791b5f3b2751f8-e82bfe2fb47c3e0e09486fab21221afc.ssl.cf5.rackcdn.com/scripps/media-type-icons/snn_icon-DOWNLOAD_OFF.svg);
    }

    &:before {
        content: url(https://85b46b791b5f3b2751f8-e82bfe2fb47c3e0e09486fab21221afc.ssl.cf5.rackcdn.com/scripps/media-type-icons/snn_icon-DOWNLOAD_OFF.svg);
    }
}