:root {
    // primary colors
    --primary-color-1: rgb(46, 100, 255);
    --primary-color-2: rgb(20, 50, 139);
    --primary-color-3: #071439;
    --primary-color-4: #030b22;
    --primary-color-5: #fff;
    --primary-color-6: #f7f7f7;
    --primary-color-7: #e5e5e5;
    --primary-color-8: #a0a0a0;

    // tag colors
    --tag-color-1: rgb(142, 46, 255);
    --tag-color-2: rgb(71, 23, 128);
    --tag-color-3: rgb(255, 159, 46);
    --tag-color-4: rgb(134, 73, 0);

    // primary gradients
    --primary-gradient-1: linear-gradient(to right, var(--primary-color-2) 0%, var(--primary-color-1) 100%);
    --primary-gradient-2: linear-gradient(to right, var(--primary-color-3) 0%, var(--primary-color-4) 100%);

    // tag gradients
    --tag-gradient-1: linear-gradient(to right, var(--tag-color-2) 0%, var(--tag-color-1) 100%);
    --tag-gradient-2: linear-gradient(to right, var(--tag-color-4) 0%, var(--tag-color-3) 100%);

    // base polygon
    --base-polygon: polygon(0 25%, 100% 15%, 100% 85%, 0 75%);

    // focus style
    --focus-style: 2px 2px 4px var(--tag-color-3), -2px -2px 4px var(--tag-color-3);
}

html {
    --base-font-size: 16px;
    --sm-screen-font-size: 16px;
}