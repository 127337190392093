.sliding-carousel-nav {
    margin-top: 1.8rem;
    width: calc(100% - (var(--slide-width)/2) - var(--slide-padding));
}

/* add shadow to right side of carousel */
.recent-news .carousel .flickity-viewport {    
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 11%;
        background-image: linear-gradient(to left, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
        background-repeat: no-repeat;
        transform: translateX(0);
        z-index: 1000;
        transition: transform 0.325s ease-in-out;
    }

    &.flickity-viewport--at-end {
        &:before {
            transform: translateX(100%);
        }
    }
}

@media screen and (max-width: 600px) {
    .recent-news .carousel .flickity-viewport {        
        &:before {
            //right: -1.5rem;
            width: 22%;
        }
    }
}

@media screen and (max-width: 501px) {
    .sliding-carousel-nav {
        width: calc(100% - var(--slide-padding));
    }
}