.team-contacts {
    &.page-section-padding {
        padding-bottom: 6rem;
    }
    
    background-color: var(--primary-color-7);
    
    .section-header .headline--thick {
        font-family: 'Montserrat';
        color: var(--primary-color-4);
        font-size: 2.6rem;
        font-weight: 500;
    }
}

.team-contacts__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 2rem;
}

.team-contacts__section {
    margin-top: 5rem;

    &:first-child {
        margin-top: -2rem;
    }
}

.team-contacts__title {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--primary-color-4);
    letter-spacing: unset;
    margin-bottom: 0;
}

@media screen and (max-width: 600px) {
    .team-contacts {
        
        .section-header .headline--thick {
            font-size: 1.75rem;
        }
    }

    .team-contacts__list {
        grid-template-columns: 1fr;
        row-gap: 4rem;
    }
}