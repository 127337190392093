.our-groups {
    //background-image: var(--primary-gradient-1); //linear-gradient(135deg, rgba(76,76,76,1) 0%,rgba(89,89,89,1) 12%,rgba(102,102,102,1) 25%,rgba(71,71,71,1) 39%,rgba(44,44,44,1) 50%,rgba(0,0,0,1) 51%,rgba(17,17,17,1) 60%,rgba(43,43,43,1) 76%,rgba(28,28,28,1) 91%,rgba(19,19,19,1) 100%); 
    background-size: 100% 1rem, cover;
    background-repeat: no-repeat;
    background-position: left bottom, left top;
    background-color: var(--primary-color-4);
}

.our-groups__wrapper.constrain-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 3.6rem;
}

.our-groups__link {
    position: relative;
    display: block;
    flex: 0 1 342px;
    padding: 0 1.3rem 9rem;
    margin: 0;
    color: var(--primary-color-5);

    &:hover {
        text-decoration: none;

        .our-groups__reveal {
            transition: transform 0.325s ease-in-out, opacity 0.225s ease-in-out;
            transform: translateY(0);
            opacity: 1;
        }
    }
}

.our-groups__item {
    margin: 0;
}

.our-groups__img {
    width: 100%;
}

.our-groups__title {
    font-size: 1.35rem;
    font-weight: 400;
    padding: 0.4rem 0;
    background-image: var(--primary-gradient-1);
    background-repeat: no-repeat;
    background-size: 50% 4px;
    background-position: top calc(50% + 2px) right;
}

.our-groups__reveal {
    position: absolute;
    top: 15.1rem;
    font-size: 1rem;
    font-weight: 400;    
    line-height: 1.4;
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 0.325s ease-in-out, opacity 0.225s ease-out 0.1s; 
}

.our-groups--Distribution {
    background-image: var(--tag-gradient-2);
}

@media screen and (max-width: 600px) {
    .our-groups__link {
        margin-bottom: 4rem;

        :hover {
            .our-groups__reveal {
                transform: translateY(-1rem);    
            }
        }

        .our-groups__reveal {
            top: 16.1rem;
            transform: translateY(-1rem);
            opacity: 1;
        }
    }

    .our-groups__title {
        margin-top: 1rem;
    }
}

