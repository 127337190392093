.dist-platforms {
    background-image: var(--primary-gradient-1); 
    background-size: 100% 1rem, cover;
    background-repeat: no-repeat;
    background-position: left bottom, top center;

    &.page-section-padding {
        padding-bottom: 7.6rem;
    }

    .headline {
        margin-bottom: 2.5rem;
    }
}

.dist-platforms__brands-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1.5rem;
}

.dist-platforms__item {
    border: 2px solid var(--primary-color-1);
    min-height: 0;
    padding-top: 1rem;
}

.dist-platforms__logo-wrapper {
    text-align: center;

    > img {
        height: 2.4rem;
    }
}

.dist-platforms__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 86%;
    margin: 1rem auto auto;
    padding: 0.75rem 1rem;
    box-sizing: border-box;
    border-top: 1px solid var(--primary-color-1);

    &.dist-platforms__list--single {
        grid-template-columns: 1fr;
        justify-content: center;
    }
}

.dist-platforms__platform {
    flex: 0 1 50%;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    .platform-logo {
        height: 1.6rem;
        display: block;
        width: auto;
        margin-right: 0.5rem;
    }

    .platform-name {
        font-size: 0.8rem;
        font-weight: 500;
    }

    .platform-coming-soon {
        font-size: 0.6rem;
        font-weight: 900;
        color: var(--primary-color-1);
    }
}

.dist-platforms__list--single .dist-platforms__platform {
    justify-self: center;
}

@media screen and (max-width: 600px) {
    .dist-platforms__brands-list {
        grid-template-columns: 1fr;
    }

    .dist-platforms__item {
        min-height: 11rem;
    }

    .platform-definitions__list { padding: 0; }
}