.stations-container {
    color: #071439;
    margin: 0 auto;

    .stations-hero {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        padding: 2.5rem 0;

        @media screen and (max-width: 37.5rem) {
            padding: 2.5rem 1.5625rem 1.25rem 1.5625rem;
            box-sizing: border-box;
        }

        &__title {
            height: 3.5625rem;
            text-align: center;
            font: normal normal normal 2.625rem/3.5625rem 'Montserrat';
            letter-spacing: 0;
            color: #071439;
            text-transform: uppercase;

            @media screen and (max-width: 37.5rem) {
                height: 2.375rem;
                font: normal normal 300 1.75rem/2.375rem 'Montserrat';
            }
        }

        &__description {
            height: 1.5625rem;
            text-align: center;
            font: normal normal medium 1.125rem/1.75rem 'Montserrat';
            letter-spacing: 0.36px;
            color: #071439;
            opacity: 1;

            @media screen and (max-width: 37.5rem) {
                font: normal normal medium 1rem/1.875rem 'Montserrat';
            }
        }
    }

    .stations-search {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 3.125rem;
        padding: 2.6875rem 0;
        /* UI Properties */
        border: 1px solid #2E64FF;
        margin-bottom: 2.5rem;
        box-sizing: content-box;

        @media screen and (max-width: 37.5rem) {
            margin-bottom: 1.25rem;
            padding: 1.25rem 1.5625rem 1.5625rem 1.5625rem;
            flex-direction: column;
            flex-wrap: initial;
            width: auto;
            height: auto;
        }

        &__title {
            display: flex;
            align-items: center;
            height: 1.325rem;
            /* UI Properties */
            text-align: left;
            font: normal normal 900 1rem/2rem 'Montserrat';
            letter-spacing: 4.1px;
            color: #071439;
            text-transform: uppercase;
            margin-right: 1.25rem;

            @media screen and (max-width: 37.5rem) {
                height: 1.325rem;
                font: normal normal 900 1rem/2rem 'Montserrat';
                margin: 0 0 0.625rem 0;
            }
        }

        &__input_wrapper {
            display: flex;
            height: 3.125rem;
            width: 40%;

            @media screen and (max-width: 37.5rem) {
                width: 100%;
            }
        }

        &__input {
            position: relative;
            display: flex;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: 1px solid #B0B0B0;
            border-radius: 0.4375rem;
            outline: none;

            font: normal normal 400 0.875rem/2rem 'Montserrat';
            color: #071439;
            text-transform: uppercase;
            padding: 0.3125rem 0.625rem;
        }
    }

    .stations-list {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__mobile-title {
            display: none;

            @media screen and (max-width: 37.5rem) {
                display: block;
                position: relative;
                height: 3.125rem;
                background: #14328B;
                text-align: left;
                font: normal normal 900 1.125rem/1.5625rem 'Montserrat';
                letter-spacing: 0px;
                color: #FFFFFF;
                padding: 0.875rem 1.5625rem 0.6875rem 1.5625rem;
                text-transform: uppercase;
                box-sizing: border-box;
            }
        }

        &__table {
            position: relative;
            width: 100%;
            border: 0;
            border-collapse: collapse;
            border-bottom: 1px solid #F9F9F9;
            margin-bottom: 6.25rem;

            @media screen and (max-width: 37.5rem) {
                display: flex;
                flex-direction: column;
                margin-bottom: 3.75rem;
            }

            thead {
                @media screen and (max-width: 37.5rem) {
                    display: none;
                }

                th {
                    position: relative;
                    height: 3.125rem;
                    background: #14328B;
                    color: #fff;
                    font: normal normal 900 1.125rem/1.5625rem 'Montserrat';
                    letter-spacing: 0px;
                    padding: 0 1.875rem;
                    text-transform: uppercase;
                    text-align: left;
                    cursor: pointer;

                    .sort {
                        position: relative;
                        margin-left: 0.9375rem;
                        display: inline;
                        height: 1.5625rem;

                        &::before {
                            position: absolute;
                            content: '';
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 0 6px 8px 6px;
                            border-color: transparent transparent #fff transparent;
                            transform: rotate(0deg);
                            top: -1px;
                        }

                        &::after {
                            position: absolute;
                            content: '';
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 0 6px 8px 6px;
                            border-color: transparent transparent #fff transparent;
                            transform: rotate(180deg);
                            bottom: -1px;
                        }

                        &-active-asc {
                            &::after {
                                opacity: 0.5;
                            }
                        }

                        &-active-desc {
                            &::before {
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }

            tbody {
                @media screen and (max-width: 37.5rem) {
                    display: flex;
                    flex-direction: column;
                }

                tr {
                    @media screen and (max-width: 37.5rem) {
                        padding: 1.25rem 1.5625rem;
                    }
                }

                td {
                    height: 3.875rem;
                    background: #FFFFFF;
                    color: #071439;
                    font: normal normal normal 1.125rem/1.325rem 'Montserrat';
                    letter-spacing: 0px;
                    padding: 0 1.875rem;
                    cursor: pointer;

                    @media screen and (max-width: 37.5rem) {
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        height: auto;
                        padding: 0 0 0.3125rem 0;
                        font: normal normal normal 1rem/1.875rem 'Montserrat';

                        &::before {
                            position: relative;
                            content: attr(data-field);
                            text-align: left;
                            font: normal normal 900 1rem/1.875rem 'Montserrat';
                            letter-spacing: 0px;
                            color: #071439;
                            width: 5.625rem;        
                        }
                    }
                }
                tr:nth-child(2n) {
                    background: #F9F9F9;
                    td {
                        background: #F9F9F9;
                    }
                }
            }
        }

        &__no_match {
            text-align: center;
        }
    }

    .station {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        padding: 2.5rem 0 3.75rem;

        @media screen and (max-width: 37.5rem) {
            padding: 2.5rem 0 1.25rem;
        }

        &__back-btn {
            display: flex;
            align-items: center;
            margin-bottom: 0.25rem;
            text-align: left;
            font: normal normal 900 1rem/1.375rem 'Montserrat';
            letter-spacing: 0;
            color: #2E64FF;
            background-color: transparent;
            border: none;
            padding-inline-start: 0;
            position: relative;
            width: fit-content;
            cursor: pointer;

            > svg {
                height: 1.6rem !important;
                width: 1.6rem !important;
                margin-block-start: -0.2rem;
            }

            @media screen and (max-width: 37.5rem) {
                padding-left: 2.6875rem;
            }
        }

        &__header{
            display: flex;
            text-align: left;
            font: normal normal normal 2.525rem/3.5625rem 'Montserrat';
            letter-spacing: 1px;
            color: #071439;
            text-transform: uppercase;
            margin-bottom: 1.25rem;

            @media screen and (max-width: 37.5rem) {
                font: normal normal 300 1.75rem/2.375rem 'Montserrat';
                padding-left: 1.5625rem;
            }
        }

        &__row {
            display: flex;
            flex-direction: row;
            background: #fff;

            @media screen and (max-width: 37.5rem) {
                flex-direction: column;
                padding: 1.25rem 1.5625rem;
            }

            &:nth-child(2n + 1) {
                background: #F9F9F9;
            }
        }

        &__description {
            display: flex;
            flex-direction: column;
            margin-bottom: 2.5rem;
        }

        &__cell {
            padding: 1.25rem 0 1.25rem;
            background: inherit;
            text-align: left;
            font: normal normal normal 1.125rem/1.875rem 'Montserrat';
            letter-spacing: 0;
            color: #071439;
            width: calc(100% - 13.75rem);
            padding-right: 1.25rem;

            &-title {
                width: 13.75rem;
                padding: 1.25rem 0 1.25rem 2.5rem;
                font: normal normal 900 1.125rem/1.875rem 'Montserrat';
            }

            @media screen and (max-width: 37.5rem) {
                padding: 0;
                width: 100%;
                box-sizing: border-box;
                @media screen and (max-width: 37.5rem) {
                    font: normal normal normal 1rem/1.375rem 'Montserrat';

                    &-title {
                        font: normal normal 900 1rem/1.375rem 'Montserrat';
                    }
                }
            }

            p {
                margin: 0 0 0.3125rem;
            }

            a {
                text-decoration: underline;
                
                &.inline {
                    display: inline;
                }
            }
        }

        .inspection-file-link {
            font-size: 0.9rem;
        }
    }
}

