@import './Fonts.scss';
.our-brands {
    // background-image: url(https://85b46b791b5f3b2751f8-e82bfe2fb47c3e0e09486fab21221afc.ssl.cf5.rackcdn.com/scripps/snn_background-image_brands.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.brands-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-gap: 1rem;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
}

.brands-list__item {
    > a {
        display:block;
    }
}

.brand-item {
    color: var(--primary-color-5);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-color: #444;
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    transition: background-size 0.325s ease-out;
}

.brand-item:hover {
    cursor: pointer;
    background-size: auto 106%;
}

.brand-item__content {
    background-color: rgba(46, 100, 255, 0);
    width: calc(100% - 2.8rem);
    height: 100%;
    padding: 0 1.4rem;  
    position: absolute;
    top: 0;
    transition: background 0.325s ease-out;  
  }
  
  .brand-item:hover .brand-item__content {
    background-color: rgba(46, 100, 255, 0.7); //rgba(46, 100, 255, 0.6);
  }

  .brand-item__link {
    position: absolute;
    bottom: 0.3rem;
    padding: 1rem 1.5rem;
    clip-path: polygon(0 25%, 100% 15%, 100% 85%, 0 75%);
    border: none;
    color: #fff;
    font-family: 'Montserrat';
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.4;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 4px;
    background-color: transparent;
    background-image: linear-gradient(to right,#41328b 0%,#2e64ff 100%);
    background-size: 0% 100%;
    background-repeat: no-repeat;  
    opacity: 0;
    transition: background 0.35s linear, opacity 0.2s ease-in 0.05s;
  }
  
  .brand-item:hover .brand-item__link,
  .programming__link:hover .brand-item__link {
    background-size: 100% 100%;
    opacity: 1;
    transition: background 0.35s cubic-bezier(0.165, 1.015, 1.000, 0.985), opacity 0.2s ease-out 0.05s;
    cursor: pointer;
  }

@media screen and (max-width: 800px) {
    .brands-list {
        row-gap: 2rem;
    }
}

@media screen and (max-width: 600px) {
    .brand-item__link {
        background-size: 100% 100%;
        opacity: 1;
        bottom: 0.5rem;
    }
}
  .brand-item__link:active {
    background: #fff;
    color: #2e64ff;
    text-decoration: none;
  }

  .brand-item__link:hover {
      text-decoration: none;
  }

  .brand-item__see-more {
    position: absolute;
    bottom: 0.3rem;
    padding: 1rem 1.5rem;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 0.85rem;
    font-weight: 100;
  }

  .brand-item__see-more-icon {
      padding: 0.1rem;
      border: 2px solid var(--primary-color-1);
      border-radius: 50%;
      vertical-align: middle;
  }

  @media screen and (max-width: 600px) {
    .brand-item__see-more {
        display: none;
    }
  }
  
  .brand-item:hover .brand-item__see-more,
  .news-slide__link:hover .brand-item__see-more,
  .programming__link:hover .brand-item__see-more {
    display: none;
  }

  .brand-item .brand-item__reveal,
  .news-slide__link .brand-item__reveal,
  .programming__link .brand-item__reveal {
    position: absolute;
    bottom: 3.7rem;
    opacity: 0;  
    width: calc(100% - 2.8rem);
    transform: translateY(100%);
    transition: transform 0.325s ease-out, opacity 0.25s ease-out;
  }
  
  .brand-item:hover .brand-item__reveal,
  .news-slide__link:hover .brand-item__reveal,
  .programming__link:hover .brand-item__reveal {
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.325s ease-out;
  }

  .programming__link .programming__reveal .brand-item__description {
    opacity: 0;
    transition: transform 0.325s ease-out, opacity 0.1s ease-out 0.15s;
  }

  .programming__link:hover .programming__reveal .brand-item__description {
    opacity: 1;
  }

  .brand-item__reveal-logo {
    color: #fff;
    margin:0;
    max-width: 8.5rem;

    > img {
      width: 100%;
    }
  }
  
  .brand-item__init-logo,
  .programming__init-name {
    position: absolute;
    bottom: 2.3rem;
    padding: 0;
    margin: 0;
    opacity: 1;  
    transition: opacity 0s ease-out 0.2s;
  }

  .brand-item__init-logo {
    max-width: 8.5rem;

    > img {
      width: 100%;
    }
  }
  
  .brand-item:hover .brand-item__init-logo,
  .news-slide__link:hover .brand-item__init-logo,
  .programming__link:hover .programming__init-name {
    opacity: 0;
    transition: opacity 0s ease-out;
  }

  .brand-item__description p,
  .brand-item__tagline {
    opacity: 0;
    color: #fff;
    transform: translateY(20px);
    transition: opacity 0.1s, transform 0.25s linear 0.15s;
  }

  @media screen and (max-width: 600px) {
    .brand-item__init-logo {
        bottom: 3.5rem;
    }

    .news-slide__link:hover .brand-item__reveal,
    .news-slide__link .brand-item__reveal {
        opacity: 1;
        transform: translateY(0);
    }

    .news-slide__link:hover .brand-item__init-logo,
    .news-slide__link .brand-item__init-logo {
        opacity: 0;
    }

    .news-slide__link .brand-item__description p {
        opacity: 1;
        transform: none;
    }
  }

  .brand-item__description p {
     font-size: 0.9rem;
     font-weight: 400;
     line-height: 1.6;
     margin: 0.25rem 0;
     padding-right: 1.4rem;
  }
  
  .brand-item__tagline {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: unset;
    text-transform: none;
    text-wrap: balance;
    margin: 0.5rem 0 0;
  }
  
  .brand-item:hover p,
  .brand-item:hover .brand-item__tagline,
  .news-slide__link:hover p,
  .news-slide__link:hover .brand-item__tagline,
  .programming__link:hover p {
    opacity: 1;
    transform: none;
  }

