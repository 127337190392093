.main-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    padding: 1.5rem 0;

    > .constrain-content {
        display: flex;
        align-items: center;
    }

    &.shrink {
        background-image: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.1) 100%);
        //box-shadow: 0 2px 20px rgba(0,0,0, 0.2);
        padding: 1.2rem 0 0.8rem;
        transition: all 0.325s ease-in-out;

        .main-logo,
        .main-nav-btn {
            transform: scale(0.8);
            transform-origin: left top;
            transition: transform 0.325s ease-out;
        }
    }
}

.main-header--solid {
    position: static;
    background-color: var(--primary-color-4);
    background-image: var(--primary-gradient-1);
    background-repeat: no-repeat;
    background-size: 100% 1rem;
    background-position-y: bottom;
    padding-bottom: 2.5rem;
}

.main-logo {
    width: 240px;
    height: 131px;
    margin: 0;
    font-size: 1rem;
}

.main-logo__link {
    display: inline-block;
    width: inherit;
    height: inherit;
    cursor: pointer;
    background-image: url(https://ewscripps.brightspotcdn.com/a5/a8/5a7526fb485aa0c9240d806fc853/scripps-networks-white-divisionofscripps4.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top left;
    filter: drop-shadow(8px 8px 10px rgba(0, 0, 0, 0.7));
}

@media screen and (max-width: 600px) {
    .main-logo {
        width: 140px;
        height: 72px;
        margin-left: 2rem;
    }
}
