.social-share {
    display: flex;
    flex-direction: column;
}

.social-share__header {
    color: #000;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 1.5rem;
}

.social-share-button {
    width: fit-content;
    background-color: transparent;
    border: 1px solid;
    border-radius: 50%;
    color: var(--primary-color-1);
    margin-bottom: 0.7rem;
    cursor: pointer;
    margin-left: 2rem;
    transition: background-color 0.325s ease-out;
    font-family: 'Montserrat';

    &:hover {
       background-color: var(--primary-color-1);

       .social-share__icon {
           fill: var(--primary-color-5);
       }
    }
}

.social-share__icon {
    padding: 0.35em 0.1em 0.2em;
    transition: fill 0.325s ease-out;
}

/* adjust padding on firefox */
@-moz-document url-prefix() {
    .social-share__icon {
        padding: 0.35em 0.2em 0.2em;
    }
}

@media screen and (max-width: 600px) {
    .social-share {
        flex-direction: row;
        align-items: center;
    }

    .social-share__header {
        margin: 0;
    }

    .social-share-button {
        margin-left: 1rem;
        margin-bottom: 0;
    }
}

