.btn {
    display: inline-block;
    border: 2px solid var(--primary-color-1);
    color: var(--primary-color-5);
    background-color: var(--primary-color-1);
    font-size: 1.125rem;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.6;
    letter-spacing: 4.5px;
    text-decoration: none;
    padding: 0.55rem 2.825rem;
    border-radius: 0.625rem;
    cursor: pointer;
    transition: all 0.325s ease-in-out;
    font-family: "Montserrat";
}

.primary-cta {
    &:focus,
    &:hover {
        background-color: var(--primary-color-3);
        border-color: var(--primary-color-3);
        text-decoration: none;
    }

    &:active {
        background-color: var(--primary-color-5);
        color: var(--primary-color-1);
        border-color: var(--primary-color-5);
        text-decoration: none;
    }    
}

.secondary-cta {
    color: var(--primary-color-4);
    border-color: var(--primary-color-1);
    background-color: var(--primary-color-5);

    &:focus,
    &:hover {
        border-color: var(--primary-color-4);
        color: var(--primary-color-1);
        text-decoration: none;
    }

    &:active {
        background-color: var(--primary-color-1);
        border-color: var(--primary-color-1);
        color: var(--primary-color-5);
        text-decoration: none;
    }
}