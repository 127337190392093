.contact-item {
    color: var(--primary-color-4);
}

.contact-item__location {
    font-weight: 500;
    font-size: 1.5rem;
    text-transform: none;
    letter-spacing: unset;
    margin: 0 0 1.25rem;
}

.contact-item__name {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.contact-item__company {
    margin-bottom: 0.5rem;
}

.contact-item__email {
    margin-bottom: 1rem;
}

.contact-item__phone {
    font-weight: 500;
    font-size: 1rem;
}

.contact-item .social-links {
    padding: 1rem 0 0;

    .social-link {
        padding: 0.45em 0.45em 0.15em;
        margin-right: 0.75rem;
        background-color: transparent;
        border-width: 1px;

        &:focus,
        &:hover {
            background-color: var(--primary-color-1);

            .social-link__icon {
                fill: var(--primary-color-7);
            }
        }
    }

    .social-link__icon {
        fill: var(--primary-color-1)
    }
}