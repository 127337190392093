.social-links {
    padding: 3.6rem 2.4rem;
}

.social-links__header {
    color: var(--primary-color-1);
    margin-top: 0;
}

.social-link {
    padding: 0.55em 0.75em 0.5em;
    border: 2px solid;
    border-radius: 50%;
    margin-right: 1.4rem;
    background-color: var(--primary-color-1);
    transition: background-color 0.325s ease-out;

    > .social-link__icon {
        transition: fill 0.225s ease-in-out;
        fill: var(--primary-color-5);
    }

    &:hover {
        background-color: var(--primary-color-5);

        > .social-link__icon {
            fill: var(--primary-color-1);
        }
    }

    &:last-child {
        margin-right: 0;
    }
}

.main-nav .social-link {
    padding: 0.65em 0.75em 0.4em;
}

@-moz-document url-prefix() {
    .social-link {
        padding: 0.55em 0.8em 0.5em;
    }

    .main-nav .social-link {
        padding: 0.65em 0.8em 0.4em;
    }
}