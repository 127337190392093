.inline-link {
    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px solid transparent;
        transition: opacity 0.225s ease-out, transform 0.325s ease-in-out;
        transform: translateY(0.25rem);
        opacity: 0;
    }

    &:focus,
    &:hover {
        text-decoration: none;

        &:before {
            transform: translateY(0);
            border-bottom-color: var(--primary-color-1);
            opacity: 1;
        }
    }

    &:active {
        text-decoration: none;
        color: var(--primary-color-2);
        
        &:before {
            transform: translateY(0);
            border-bottom-color: var(--primary-color-2);
            opacity: 1;
        }
    }
}