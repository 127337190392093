.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -85%) scale(0, 0);
    transform-origin: center;
    width: min(960px, 60vw);
    min-height: min(540px, 60vw * 0.5625);
    min-height: max-content;
    box-sizing: border-box;
    background: #444;
    z-index: 210;
    opacity: 0;
    //box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
    box-shadow: 1px 1px 2px rgba(0,0,0, 0.1),
                2px 2px 4px rgba(0,0,0, 0.1),
                4px 4px 8px rgba(0,0,0, 0.1),
                8px 8px 16px rgba(0,0,0, 0.1),
                16px 16px 32px rgba(0,0,0, 0.1),
                32px 32px 64px rgba(0,0,0, 0.1);
    border: 1px solid var(--primary-color-5);
    transition: transform 0.25s ease-in-out, opacity 0.125s ease-out 0.1s;
}

@media screen and (min-aspect-ratio: 1620/835) {
    .modal {
        width: min(960px, 60vh * 1.775);
        height: min(60vh, 540px);
    }
}

.modal--active {
    transform: translate(-50%, -66%) scale(1, 1);
    opacity: 1;
    transition: transform 0.35s ease-in-out, opacity 0.225s ease-out 0.1s;
}

.modal__close-btn {
    position: absolute;
    top: -3rem;
    right: -0.3rem;
    z-index: 210;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    text-shadow: 1px 1px 4px rgba(0,0,0, 0.5), -1px -1px 4px rgba(0,0,0, 0.5);
    transform: none;
    transform-origin: center;
    transition: transform 0.325s ease-in-out;
    padding: 0;
    line-height: 1;
    height: auto;
}

.modal__close-btn:hover {
    transform: rotate(180deg);
    transform-origin: center;
}

.modal__close-btn-icon {
    fill: var(--primary-color-5);
}

@media screen and (max-width: 900px) {
    .modal {
        width: 80vw;
        //max-height: calc(80vw * 0.5625);
    }
}

@media screen and (max-width: 768px) {
    .modal {
        width: 90vw;
        //max-height: calc(90vw * 0.5625);
    }
}

@media screen and (min-aspect-ratio: 1/1) and (max-height: 460px) {
    .modal {
        max-height: 90vh;
        width: calc(90vh * 1.7777);
        overflow-y: scroll;
    }

    .modal__close-btn {
        right: -0.3rem;
        top: -0.3rem;
    }
}

.modal-entry-enter {
    opacity: 0;
    transform: translate(50%, 50%) scale(0.1);
}

.modal-entry-enter-active,
.modal-entry-exit-active {
    transition: opacity 0.225s ease-out, transform 0.35s ease-in-out;
}

.modal-entry-exit-active {
    transform: translate(50%, 50%) scale(1); 
    opacity: 1;
}

.modal-entry-exit {
    opacity: 0;
    transform: translate(50%, 50%) scale(0.1);
}

