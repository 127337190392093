.news-slide {
    width: var(--slide-width);
    padding-top: calc(var(--slide-width) * 1.548117);
    overflow: hidden;
    margin-right: var(--slide-padding);

    &:last-child {
        margin-right: calc(var(--slide-padding) * 2);
    }
}

@media screen and (max-width: 600px) {
    .news-slide {
        overflow: visible;
    }
}

.news-slide__link {
    position: absolute;
    top: 0;
    width: 100%;
    padding-top: calc(100% * 1.548117);
    box-sizing: content-box;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: var(--primary-color-5);

    &:focus {
        box-shadow: inset 1px 1px 0 yellow, inset -1px -1px 0 yellow;
    }
}

.news-slide__link:hover .news-slide__tagline-init {
    display: none;
}

.news-slide__init-content {
    position: absolute;
    bottom: 2.75rem;
    opacity: 1;  
    transition: opacity 0s ease-out 0.2s;
}
  
.brand-item:hover .brand-item__init-logo,
.news-slide__link:hover .news-slide__init-content {
    opacity: 0;
    transition: opacity 0s ease-out;
}

@media screen and (max-width: 600px) {
    .news-slide__init-content {
        opacity: 0;
    }        
}

.news-slide__category {
    text-transform: none;
    font-size: 0.85rem;
    font-weight: 500;
    letter-spacing: unset;
    padding: 0.35rem 1.5rem;
    margin: 0;
    width: fit-content;
}

.news-slide__tagline {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: unset;
    text-transform: none;
    text-wrap: balance;
    padding-right: 1.4rem;
    margin: 0.5rem 0 0;
}

.news-slide__tagline-init {
    opacity: 1;
}

.news-slide__link:hover .brand-item__link {
    background-size: 100% 100%;
    opacity: 1;
    transition: background 0.35s cubic-bezier(0.165, 1.015, 1.000, 0.985), opacity 0.2s ease-out 0.05s;
    cursor: pointer;
  }