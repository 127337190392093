.article-contacts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
    row-gap: 2rem;
    margin-top: 3.5rem;
}

.contact__type {
    font-size: 1.05rem;
    font-weight: 700;
    margin-bottom: 0.75rem;
}

.contact__name,
.contact__company,
.contact__phone {
    margin-bottom: 0.5rem;
}

.contact__email {
    a {
        font-weight: 700;
    }
}
