.media-links__header {
    margin-top: 4rem;
    font-weight: 700;
}

.article__media-link {
    background-color: transparent;
    border: none;
    color: var(--primary-color-1);
    text-decoration: none;
    font-size: 1.05rem;
    font-weight: 700;
    text-transform: uppercase;
}

.media-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
    row-gap: 2.5rem;
}