.brand-programming {
    &.page-section-padding {
        padding-bottom: 8.6rem;
    }

    background-image: var(--primary-gradient-1); // linear-gradient(135deg, rgba(76,76,76,1) 0%,rgba(89,89,89,1) 12%,rgba(102,102,102,1) 25%,rgba(71,71,71,1) 39%,rgba(44,44,44,1) 50%,rgba(0,0,0,1) 51%,rgba(17,17,17,1) 60%,rgba(43,43,43,1) 76%,rgba(28,28,28,1) 91%,rgba(19,19,19,1) 100%); 
    background-size: 100% 1rem; // cover;
    background-repeat: no-repeat;
    background-position: left bottom; //left top;
    background-color: var(--primary-color-4);
}

.programming__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 3rem;
    margin-top: 4rem;

    @media screen and (max-width: 1400px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 960px) {
        grid-template-columns: 1fr;
    }
}

.programming__item {
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 50.5%;
    width: 100%;
    object-fit: cover;
}

.programming__content {
    background-color: rgba(46, 100, 255, 0);
    width: calc(100% - 2.8rem);
    height: 100%;
    padding: 0 1.4rem;
    position: absolute;
    top: 0;
    transition: background 0.325s ease-out;
}

.programming__link {
    color: var(--primary-color-5);

    &:hover {
        text-decoration: none;

        .programming__content {
            background-color: rgba(46, 100, 255, 0.7);
        }
    }

    .programming__init-name,
    .programming__reveal-name {
        font-size: 1.4rem;
        font-weight: 500;
        text-transform: uppercase;
    }

    .programming__init-name {
        bottom: 2.2rem;
        left: 1.6rem;
    }

    .brand-item__link {
        bottom: -1.6rem;
        font-size: 0.85rem;
        letter-spacing: 2px;
    }
}

.programming__link .brand-item__reveal {
    bottom: 2rem;
}

.programming__reveal {
    left: 1.6rem;
}

.programming__item .brand-item__see-more {
    bottom: -0.2rem;
}

// .programming__reveal .programming__reveal-name {
//     text-shadow: 1px 1px 2px rgb(0 0 0 / 80%);
// }

// .brand-item__description {
//     text-shadow: 1px 1px 2px rgb(0 0 0 / 80%), 2px 2px 4px rgb(0 0 0 / 40%);
// }

@media screen and (max-width: 600px) {
    .programming__grid {
        grid-template-columns: 1fr;
    }

    .brand-programming {
        &.page-section-padding {
            padding-top: 4rem;
        }
    }
}