.stat-item {
    display: flex;
    align-items: stretch;
}

.stat-item__value {
    font-size: 5rem;
    font-family: 'Bebas Neue';
    color: var(--primary-color-5);
    padding-right: 1rem;
}

.stat-item__label {
    font-size: 1.125rem;
    letter-spacing: 3.42px;
    white-space: nowrap;
    color: var(--primary-color-5);
    text-transform: uppercase;
    font-weight: 900;
    line-height: 1.4;
    padding-top: 0.7rem;
    background-image: var(--primary-gradient-1);
    background-position: left bottom 1rem;
    background-size: 100% 0.5rem;
    background-repeat: no-repeat;
}