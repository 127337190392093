input[type=range] {
    --thumb-height: 1.3rem;
    --thumb-width: 4.8rem;
    --black-bar: linear-gradient(to bottom, rgba(0,0,0, 0.6) 0%, rgba(0,0,0, 0.4) 100%);
    --bg-position-1: top 0.3rem left calc(50% + 5px);
    --bg-position-2: top 0.3rem left calc(50% - 5px);
    --bg-position-3: top 0.3rem left 50%;
    --bg-position-4: top left;
    --bg-size: 2px 0.75rem;
    width: 100%;
    margin: 1.6rem 0;
    background-color: transparent;
    -webkit-appearance: none;
  }

  input[type=range]:focus {
    outline: none;
  }


  input[type=range]::-webkit-slider-runnable-track {
    background: var(--primary-color-5);
    border: none;
    width: 100%;
    height: 2px;
    cursor: pointer;
  }

  input[type=range]::-webkit-slider-thumb {
    margin-top: -0.55rem;
    width: var(--thumb-width);
    height: var(--thumb-height);
    background-image: var(--black-bar), 
                      var(--black-bar), 
                      var(--black-bar), 
                      var(--primary-gradient-1);
    background-position: var(--bg-position-1),
                         var(--bg-position-2), 
                         var(--bg-position-3), 
                         var(--bg-position-4);
    background-size:  var(--bg-size), 
                      var(--bg-size), 
                      var(--bg-size), 
                      100%;
    background-repeat: no-repeat;
    border-radius: 6px;
    cursor: pointer;
    -webkit-appearance: none;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: var(--primary-color-5);
  }

  input[type=range]::-moz-range-track {
    background: var(--primary-color-5);
    border: none;
    border-radius: 1.3px;
    width: 100%;
    height: 2px;
    cursor: pointer;
  }

  input[type=range]::-moz-range-thumb {
    width: var(--thumb-width);
    height: var(--thumb-height);
    background-image: var(--black-bar), 
                      var(--black-bar), 
                      var(--black-bar), 
                      var(--primary-gradient-1);
    background-position:  var(--bg-position-1),
                          var(--bg-position-2), 
                          var(--bg-position-3), 
                          var(--bg-position-4);
    background-size: var(--bg-size), 
                     var(--bg-size), 
                     var(--bg-size), 
                      100%;
    background-repeat: no-repeat;
    border-radius: 6px;
    border: none;
    cursor: pointer;
  }

  input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 16.8px 0;
    color: transparent;
    width: 100%;
    height: 8.4px;
    cursor: pointer;
  }

  input[type=range]::-ms-fill-lower {
    background: var(--primary-color-5);
    border: none;
    border-radius: 2.6px;
  }

  input[type=range]::-ms-fill-upper {
    background: var(--primary-color-5);
    border: none;
    border-radius: 2.6px;
  }

  input[type=range]::-ms-thumb {
    width: 4.2rem;
    height: 1.15rem;
    background-image: var(--black-bar), 
                      var(--black-bar), 
                      var(--black-bar), 
                      var(--primary-gradient-1);
    background-position:  var(--bg-position-1),
                          var(--bg-position-2), 
                          var(--bg-position-3), 
                          var(--bg-position-4);
    background-size: var(--bg-size), 
                     var(--bg-size), 
                     var(--bg-size), 
                      100%;
    background-repeat: no-repeat;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    /* Needed to keep the Edge thumb centred */
    margin-top: 0px;
  }

  input[type=range]:focus::-ms-fill-lower {
    background: var(--primary-color-5)
  }

  input[type=range]:focus::-ms-fill-upper {
    background: var(--primary-color-5);
  }

  /* old school IE stuff */
  @supports (-ms-ime-align:auto) {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    input[type=range] {
      margin: 0;
      /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
  }
  