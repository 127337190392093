.banner-slide {
    width:100%;
    overflow: hidden;
}

.banner-slide__image-wrapper {
    width: inherit;
    padding-top: 63.3%;
    box-sizing: border-box;
    background-color: rgba(255,255,255, 0.6);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
}

.banner-slide__content-wrapper {
    position: absolute;
    bottom: 22%;
    width: 42rem;
}

.banner-slide__headline {
    width: 32rem;
    line-height: 1.05;
    margin: 0 0 1.6rem;
    text-shadow: 2px 2px 4px rgba(0,0,0, 0.8);
}

.banner-slide__content {
    margin: 0 0 3.1rem;
    font-size: 1.15rem;
    line-height: 1.6;
    font-weight: 500;
    text-shadow: 2px 2px 4px rgba(0,0,0, 0.8);
}

.banner-slide--mobile { display: none; }

@media screen and (max-width: 1170px) {
    .banner-slide__headline {
        font-size: 4rem;
        width: 36rem;
    }
}

@media screen and (max-width: 1050px) {
    .banner-slide__headline {
        font-size: 3.5rem;
        width: 36rem;
    }

    .banner-slide__image-wrapper {
        padding-top: max(63.3%, 640px);
    }
}

@media screen and (max-width: 720px) {
    .banner-slide--mobile { 
        display: block; 
    
        .constrain-content {
            width: 100%;
            max-width: unset;
            margin: 0 auto;
        }
    }

    .banner-slide__image-wrapper:not(.banner-slide--mobile) { display: none; }

    .banner-slide__image-wrapper {
        padding-top: min(213%, 800px);
        background-size: cover;
    }

    .banner-slide__content-wrapper {
        bottom: 6rem;
        width: 100%;
        padding: 0 1.5rem;
        box-sizing: border-box;

        .btn {
            margin: 0 auto;
            width: fit-content;
            display: block;
        }
    }

    .banner-slide__headline {
        width: 100%;
    }
}