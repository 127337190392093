.platform-definitions__header {
    font-size: 1.6rem;
    font-weight: 500;
    text-align: center;
    letter-spacing: unset;
}

.platform-definition {
    display: flex;
}

.platform-definition__icon {
    display: block;
    width: 1.9rem;
    height: auto;
    margin-right: 0.75rem;
    align-self: flex-start;
    filter: grayscale(1) brightness(0) invert(1);
}

.platform-definitions__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4rem;
    row-gap: 1.5rem;
    padding: 0 5rem 0 0;
}

.platform-definition__abbr {
    font-weight: 700;
}

.platform-definition__definition {
    font-size: 0.8rem;
    font-weight: 500;
}

@media screen and (max-width: 600px) {
    .platform-definitions__list {
        grid-template-columns: 1fr;
    }
}