.working-wrapper {
    --working-height: 140px;
    position: relative;
    min-height: var(--working-height);
}

.spinner {
    --spinner-width: 48px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    margin: auto;
}

.spinner__wrapper { 
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);     
    width: var(--spinner-width);
    height: var(--spinner-width);    
    border-radius: 60%;
    background: none repeat scroll 0 0 transparent;
    overflow: hidden; 

    > span {
        position: absolute;
        height: 100%; 
        width: 50%;
        overflow: hidden;
    }
}

.news-item .spinner__wrapper {
    background-color: rgba(0,0,0, 0.8);
}

.spinner--pie {
    transform: translate(-50%, -50%) rotate(0); 
}

.spinner--ring:after {
    content: "";
    position: absolute;
    top: calc((var(--spinner-width) - 46px) * 0.17 + 9px); 
    left: calc((var(--spinner-width) - 46px) * 0.17 + 9px);
    display: block;
    height: calc(var(--spinner-width) * 0.608695); 
    width: calc(var(--spinner-width) * 0.608695);
    background: none repeat scroll 0 0 rgb(255,255,255);
    border-radius: 50%;
}

.spinner__left  { left: 0 }
.spinner__right { left: 50% }

.spinner__anim {
    position: absolute;
    left: 100%; 
    top: 0;
    height: 100%; 
    width: 100%;
    border-radius: 999px;
    background: none repeat scroll 0 0 #fff;
    opacity: 0.8;
    animation: ui-spinner-rotate-left infinite;
    animation-delay: 0s;
    animation-duration: 2s;
    animation-timing-function: linear;
    transform-origin: 0 50% 0;
}

.spinner__left .spinner__anim {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    animation-delay: 1s;
    background-color: #2e64ff;
}

.spinner__right .spinner__anim {
    animation-name: ui-spinner-rotate-right;
    animation-delay: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    left: -100%;
    transform-origin: 100% 50% 0;
}

@keyframes ui-spinner-rotate-left {
    0%{transform:rotate(0deg); opacity: 0.4}
    25%{transform:rotate(180deg); opacity: 0.8}
    50%{transform:rotate(180deg); }
    75%{transform:rotate(360deg); opacity: 0}
    100%{transform:rotate(360deg)}
}
@keyframes ui-spinner-rotate-right {
    0%{transform:rotate(0deg)}
    25%{transform:rotate(0deg); opacity: 0}
    50%{transform:rotate(180deg); opacity: 0.4}
    75%{transform:rotate(180deg); opacity: 0.8}
    100%{transform:rotate(360deg); opacity: 0.4}
}