@charset "UTF-8";
@import-normalize;

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@300;400;500;700;900&display=swap');
 
html {
    font-size: var(--base-font-size);
}
body {
    margin: 0;
    background-color: var(--primary-color-4);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Montserrat';
    font-size: 1rem;
    font-weight: 400;
    color: var(--primary-color-5);
}

@media (prefers-reduced-motion: no-preference) {
    .fade-in__wrapper {
        animation-name: page-fade-in;
        animation-fill-mode: backwards;
    }
}

@keyframes page-fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* normalize override (instead of "bolder") */
:where(b,strong) {
    font-weight: bold;
}
 
h1 {
    font-family: 'Bebas Neue';
    font-weight: 300;
    font-size: 5rem;
}

h2,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat';
    font-weight: 300;
    letter-spacing: 4.6px;
    text-transform: uppercase;
}

h2 {
    font-size: 3rem;
}

h3 {
    font-size: 2.4rem;
}

h4,
h5,
h6 {
    font-size: 1rem;
    font-weight: 900;
}

.headline {
    font-weight: 300;
    letter-spacing: unset;
    margin: 0.9rem 0;
}

.headline--black {
    color: var(--primary-color-3);
}

.headline--center {
    text-align: center;
}

.headline--thick {
    font-family: 'Bebas Neue';
    font-size: 5rem;
}

@media screen and (max-width: 600px) {
    html {
        font-size: var(--sm-screen-font-size);
    }

    h1 {
        font-size: 3.125rem;
    }

    h2 {
        font-size: 1.75rem;
    }

    h3 {
        font-size: 1.75rem;
    }

    .headline--thick {
        font-size: 3.125rem;
    }
}

p {
    line-height: 1.88;
}

a {
    position: relative;
    display: inline-block;
    color: var(--primary-color-1);
    text-decoration: none;

    &:focus,
    &:hover {
        text-decoration: underline;
    }

    &:active {
        color: var(--primary-color-2);
    }
}

.content-wrapper {
    transition: filter 0.325s ease-out;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    transition: opacity 0.125s ease-out;
}

.content-wrapper--blur {
    filter: blur(4px);
}

.content-wrapper--white {
    background-color: #fff;
}

.constrain-content {
    width: 80%;
    max-width: 80rem;
    margin: 0 auto;
}

.constrain-content-left {
    width: 90%;
    margin: 0 0 0 auto;
}

.page-section-padding {
    padding: 2.4rem 0 4.8rem;
}

@media screen and (max-width: 600px) {
    .constrain-content {
        width: 100%;
        max-width: unset;
        margin: 0 auto;
    }

    .constrain-content-left {
        width: 100%;
    }

    .page-section-padding {
        padding: 4rem 1.5rem 4.8rem;
    }
}

@media screen and (min-width: 1615px) {
    .constrain-content-left {
        width: calc(100vw - (100vw - 1294px) / 2);
    }
}

.gradient-header {
    padding: 1rem 1.5rem;
    margin: 0 auto 1rem;
    width: fit-content;
    clip-path: var(--base-polygon); 
    color: var(--primary-color-5);
    background-color: transparent;
    background-image: var(--primary-gradient-1);
    background-size: 100% 100%;
    background-repeat: no-repeat; 
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 2;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 4px;
    border: none;
    text-decoration: none;
}

@media screen and (max-width: 600px) {
    .gradient-header {
        white-space: nowrap;
    }
}

.gradient-header--left {
    margin: 0 0 1rem;
}

@import 'Utilities';

