.news-item__wrapper {
    display: grid;
    grid-template-columns: 5fr 1fr;
    padding: 3rem 0 6rem;

    a:not(.article__media-link, .inline-link) {
        text-decoration: underline;
        font-weight: 700;
        transition: color 0.325s ease-out;

        &:focus,
        &:hover {
            color: var(--primary-color-2);
        }
    }
}

.article__title {
    font-family: 'Montserrat';
    font-size: 2.5rem;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;
}

.article__date-author {
    margin: 1rem 0 2.5rem;
    font-size: 1.15rem;
    font-weight: 500;
    display: flex;
}

.article__date {
    flex: none;
}

.article__author {
    position: relative;
    padding-left: 3rem;
    flex: auto;

    &:before {
        content: '';
        position: absolute;
        width: 0.3rem;
        height: 2rem;
        top: -0.2rem;
        left: 1.35rem;
        border: 1px solid var(--primary-color-8);
        border-width: 0 1px;
    }
}

.article-img__wrapper {
    > img {
        width: 100%;
    }
}

.news-item__article {
    color: #000;

    li {
        margin-block-end: 1rem;
        line-height: 1.4;
    }

    ul {
        margin-block-end: 2rem;
    }
}

.article__main-content {
    max-width: 41.5rem;
    margin: auto;

    b {
        font-size: 1rem;
        font-weight: 900;
    }

    figure {
        margin: 2.4rem 0;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid var(--primary-color-1);

        > img {
            width: 100%;
        }
    }

    figcaption {

        h3 {
            font-size: 1.2rem;
            text-transform: none;
            letter-spacing: unset;
            font-weight: 700;
            font-family: 'Montserrat';
            line-height: 1.4;
        }
    }

    iframe {
        border: none;
    }
}
@media screen and (max-width: 768px) {
    .article__main-content figure {
        max-width: 100%;
        > img {
            height: auto;
        }
    }
}
@media screen and (max-width: 600px) {
    .news-item__wrapper {
        display: block;
        padding: 3rem 1.5rem 6rem;
    }

    .news-item__aside {
        margin-top: 3rem;
    }

    .media-links,
    .article-contacts {
        grid-template-columns: 1fr;
    }

    .article__title {
        font-size: 1.75rem;
    }

    .article__date-author {
        font-size: 0.875rem;
    }
}
