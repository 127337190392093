.about-scripps {
    min-height: 20rem;
    background-image: var(--primary-gradient-1);// linear-gradient(135deg, rgba(76,76,76,1) 0%,rgba(89,89,89,1) 12%,rgba(102,102,102,1) 25%,rgba(71,71,71,1) 39%,rgba(44,44,44,1) 50%,rgba(0,0,0,1) 51%,rgba(17,17,17,1) 60%,rgba(43,43,43,1) 76%,rgba(28,28,28,1) 91%,rgba(19,19,19,1) 100%); 
    background-size: 100% 1rem; //cover;
    background-repeat: no-repeat;
    background-position: left bottom; //left top;
    background-color: var(--primary-color-4);
}

.about-scripps__content {
    max-width: 51rem;
    margin: auto;
    text-align: center;
}

.about-scripps__info-list {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 52rem;
    margin: 3.4rem auto 0;
}

.about-scripps__info-list-item {
    flex: 0 1 calc(33% - 1rem);
    padding-right: 1rem;
    padding-bottom: 1rem;
    box-sizing: border-box;

    .stat-item {
        justify-content: center;
    }

    &:last-child {        
        > .stat-item {
            justify-content: end;
        }
    }

    &:first-child {
        > .stat-item {
            justify-content: start;
        }
    }
}

@media screen and (max-width: 900px) {
    .about-scripps__info-list {
        flex-wrap: wrap;
        justify-content: center;
    }

    .about-scripps__info-list-item {
        padding:  0 1rem;
        margin-bottom: 1rem;
    
        &:last-child {
            margin-left: unset;
            padding-right: 0;
        }
    }
}

@media screen and (max-width: 600px) {
    .about-scripps__info-list-item { 
        padding: 0 1rem;
    }
}