.main-nav {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--primary-color-5);
    transform: translateY(-100%);
    opacity: 0;
}

@media screen and (max-width: 600px) {
    .main-nav {
        overflow-y: scroll;
        max-height: 100vh;
        transform: translateY(-150%);
    }
}

.main-nav--active {
    z-index: 100;
    transform: translateY(0);
    opacity: 1;
}

/* CSSTransition Classes */
.main-nav-enter {
    opacity: 0;
    transform: translateY(-100%);
}
.main-nav-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.425s ease-in-out, opacity 0.425s ease-out 0.1s;
}
.main-nav-exit {
    opacity: 1;
    transform: translateY(0);
}
.main-nav-exit-active {
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 0.325s ease-in-out, opacity 0.225s ease-out 0.1s;
}

.main-nav__list,
.main-nav__header {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    width: 80%;
    max-width: 71.25rem;
    margin: auto;
    margin-top: 2rem;
}

.main-nav__header {
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 2.4rem;
}

.nav-logo {
    width: 190px;
    height: 102px;
    background-image: url(https://ewscripps.brightspotcdn.com/7a/de/67d75cb5404bb80d0d57261a1189/scripps-networks-blue-divisionofscripps4.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin: 0;
}

.nav-logo__link {
    display: inline-block;
    width: inherit;
    height: inherit;
    cursor: pointer;
}

.main-nav__item {
    flex: 0 1 50%;
    margin: 0;
    margin-bottom: -3.5rem;
    display: inline-block;
}

.main-nav__link {
    display: block;
    color: var(--primary-color-1);
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'Bebas Neue';
    font-size: 3.7rem;
    padding: 3.8rem 3.5rem 3.3rem 2.4rem;
    clip-path: polygon(0 25%, 100% 15%, 100% 85%, 0 75%);
    width: fit-content;
    background-size: 0% 100%;
    background-image: var(--primary-gradient-2);
    background-color: transparent;
    background-repeat: no-repeat;  
    transition: background 0.325s ease-out, color 0.225s ease-out;

    &:focus,
    &:hover {
        color: var(--primary-color-5);
        background-size: 100% 100%;
        text-decoration: none;
    }

    &:active {
        background-size: 0 100%;
        border: 1px solid var(--primary-color-1);
        color: var(--primary-color-1);
        text-decoration: none;

        &:before {
            content: "";
            position: absolute;
            top: 35px;
            height: 2px;
            width: 116%;
            transform: rotate(-2.5deg);
            background-color: var(--primary-color-1);
            left: 0;
            box-shadow: none;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: 35px;
            height: 2px;
            width: 116%;
            transform: rotate(2.5deg);
            background-color: var(--primary-color-1);
            left: 0;
        }
    }
}

@media screen and (max-width: 768px) {
    .main-nav__item {
        flex: 0 1 100%;
        margin-bottom: -5rem;

        &:last-child {
            margin-top: 1rem;
        }

        .social-link {
            margin-right: 0.5rem;
        }
    }
}

@media screen and (max-width: 600px) {
    .main-nav__list,
    .main-nav__header {
        width: 100%;
        margin-top: 1.5rem;
        padding: 0 0 0 2rem;
        box-sizing: border-box;
    }

    .main-nav__list {
        flex-direction: column;
        margin-top: 0;
    }

    .main-nav__item {
        margin-bottom: -6rem;
    }

    .main-nav__link {
        font-size: 3rem;
    }

    .nav-logo {
        width: 140px;
        height: 72px;
    }
}