.main-footer {
    margin-top: auto;
    padding: 3.2rem 0;
    background-color: #000;

    > .constrain-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas: 
            "footer-logo footer-brands footer-privacy footer-corporate"
            "footer-logo footer-brands footer-info footer-social";
        row-gap: 2.4rem;
    }
}

@media screen and (max-width: 1100px) {
    .main-footer {
        > .constrain-content {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
            "footer-brands footer-privacy footer-corporate"
            "footer-brands footer-info footer-social";
        }
    }

    .footer-logo-wrapper {
        display: none;
    }
}

@media screen and (max-width: 840px) {
    .main-footer {
        > .constrain-content {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "footer-brands footer-privacy"
            "footer-brands footer-info"
            "footer-corporate footer-social";
        }
    }
}

@media screen and (max-width: 600px) {
    .main-footer {
        padding: 3.2rem 1.5rem;
    }
}

@media screen and (max-width: 500px) {
    .footer-logo-wrapper {
        display: block;
    }

    .main-footer {
        
        > .constrain-content {
        grid-template-columns: 1fr;
        grid-template-areas:
            "footer-logo"
            "footer-brands"
            "footer-privacy"
            "footer-info"
            "footer-corporate"
            "footer-social";
        }
    }
}

.footer-logo-wrapper {
    grid-area: footer-logo;
}

.footer-nav__brands {
    grid-area: footer-brands;
}

.footer-nav__privacy {
    grid-area: footer-privacy;
}

.footer-nav__info {
    grid-area: footer-info;
}

.footer-nav__corporate {
    grid-area: footer-corporate;
}

.footer-nav__social {
    grid-area: footer-social;
    white-space: nowrap;
}

@media screen and (max-width: 960px) {
    .footer-nav__social {
        white-space: unset;
    }
}

.secondary-logo {
    margin: 0;
    width: 196px;
    height: 100px;
    background-image: url(https://ewscripps.brightspotcdn.com/a5/a8/5a7526fb485aa0c9240d806fc853/scripps-networks-white-divisionofscripps4.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;

    > a {
        width: inherit;
        height: inherit;
        display: block;
    }
}

.footer-header {
    margin: 0 0 0.8rem 0;
    font-size: 0.8rem;
    font-weight: 900;
}

.footer-nav__item {
    background-repeat: no-repeat;
    background-image: linear-gradient(to right, var(--primary-color-1) 0%, var(--primary-color-1) 100%);
    background-size: 0.2rem 100%;
    padding: 0.4rem 0;

    &:last-child {
        padding-bottom: 0;
    }

    &:first-child {
        padding-top: 0;
    }
}

.footer-nav__link {
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.3rem 0.8rem 0.2rem;
    color: var(--primary-color-5);
    background-repeat: no-repeat;
    background-image: linear-gradient(to right, var(--primary-color-1) 0%, var(--primary-color-1) 100%);
    background-size: 0.2rem 100%;
    transition: background-size 0.25s linear;

    &:focus,
    &:hover {
        background-size: 100% 100%;
        transition: background-size 0.25s ease-in-out;
        text-decoration: none;
    }
}

.footer-nav__social {
    .social-links {
       padding-top: 0;
       padding-left: 0;
    }

    .social-links__header {
        font-size: 0.8rem;
        color: var(--primary-color-5);
    }

    .social-link {
        border-color: var(--primary-color-5);
        background-color: transparent;
        margin-right: 1rem;
        margin-bottom: 1rem;

        > .social-link__icon {
            fill: var(--primary-color-5);
        }

        &:hover {
            background-color: var(--primary-color-1);
            border-color: var(--primary-color-1);
        }
    }
}