.page-not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6rem 0;
    min-height: 40vh;

    &-bg-black {
        background-color: #000;
    }
}

.page-not-found__message {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
}

.news-item .page-not-found h1,
.news-item .page-not-found__message {
    color: #000;
}


