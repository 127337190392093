.watch-on__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.5rem;
}

.watch-on__item {
    position: relative;
    display: flex;
    align-items: center;
    color: #000;
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 2.3rem;
    min-height: 2.3rem;

    > img {
        width: 1.8rem;
        flex: 0 1 auto;
        display: block;
        margin-right: 0.6rem;
    }

    &:before {
        content: attr(data-tip-text);
        font-size: 0.9rem;
        position:absolute;
        bottom: calc(100% + 10px + 0.25rem);
        left: 50%;
        transform: translate(-50%, 40%) scale(0);
        transform-origin: bottom;
        margin-left: 1rem;
        width: 10rem;
        padding: 1rem;
        border-radius: 6px;
        background-color: var(--primary-color-1);
        background-image: linear-gradient(to bottom, rgb(20, 50, 139) 0%, rgb(46, 100, 255) 100%);
        color: var(--primary-color-5);
        text-align: center;
        opacity: 0;
        box-shadow: 2px 2px 4px rgba(0,0,0, 0.1), 4px 4px 8px rgba(0,0,0, 0.1), 6px 6px 18px rgba(0,0,0, 0.2);
        transition: opacity 0.125s ease-out, transform 0.225s ease-in-out;
    }

    &:hover:before {
        opacity: 1;
        transform: translate(-60%, 0) scale(1);
    }

    &:after {
        content: "";
        position:absolute;
        left: 0.3rem;
        top: calc(-10px - 0.25rem);        
        border:10px solid;
        border-color: var(--primary-color-1) transparent transparent transparent;
        opacity: 0;
        transform: translateY(-20%);
        transition: opacity 0.05s ease-out;
    }

    &:hover:after {
        opacity: 1;
        transform: none;
        transition: opacity 0.325s ease-out 0.1s;
    }
}

.watch-on__item .platform-coming-soon {
    font-size: 0.7rem;
    color: var(--primary-color-1);
}