.text-link {
    position: relative;
    display: inline-block;
    clip-path: var(--base-polygon);
    border: 1px solid transparent;
    background-image: var(--primary-gradient-1);
    background-repeat: no-repeat;
    background-size: 0.25rem 100%;
    background-color: transparent;
    padding: 0.85rem 0.75rem 0.75rem;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.63;
    letter-spacing: 3.84px;
    text-decoration: none;
    color: var(--primary-color-5);
    cursor: pointer;
    transition: all 0.325s ease-in-out;
    font-family: 'Montserrat';

    &:focus,
    &:hover {
        background-size: 100% 100%;
        color: var(--primary-color-5);
        text-decoration: none;
    }

    &:active {
        background-size: 0 100%;
        border: 1px solid var(--primary-color-1);
        color: var(--primary-color-1);
        text-decoration: none;

        &:before {
            content: "";
            position: absolute;
            top: 9px;
            height: 2px;
            width: 116%;
            transform: rotate(-1.5deg);
            background-color: var(--primary-color-1);
            left: 0;
            box-shadow: none;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: 9px;
            height: 2px;
            width: 116%;
            transform: rotate(1.5deg);
            background-color: var(--primary-color-1);
            left: 0;
        }
    }
}

.text-link--read-more {
    background-size: 0 100%;
}