.videos {
    background-color: var(--primary-color-5);
    background-image: var(--primary-gradient-1); 
    background-size: 100% 1rem;
    background-repeat: no-repeat;
    background-position: left bottom;

    .section-header {
        color: #000;
    }

    .section-header__description {
        font-size: 1rem;
        font-weight: 300;
    }
}

.videos__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 2rem;
    margin-top: 5rem;
}

@media screen and (max-width: 600px) {
    .videos__grid {
        grid-template-columns: 1fr;
        margin-top: 4rem;
    }

    .videos .section-header {
        margin-top: -1rem;
    }
}