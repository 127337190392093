.section-header {
    margin-top: 3.8rem;
    margin-bottom: 3rem;
    padding-bottom: 2rem;
    background-image: var(--primary-gradient-1);
    background-repeat: no-repeat;
    background-size: 90% 3px;
    background-position: bottom right;
}

@media screen and (min-width: 1615px) {
    .section-header {
        background-size: calc(100vw - ((100vw - 1294px) / 2)) 3px;
    }
}

.section-header__title {
    font-family: 'Bebas Neue';

    &:last-child {
        margin-bottom: 0;
    }
}

.section-header__description {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5;
    margin: -0.75rem 0 0.9rem;
    width: 66%;
}

@media screen and (max-width: 600px) {
    .section-header {
        background-size: 100% 3px;
        margin-top: 0;
        padding-bottom: 1rem;
    }

    .section-header__description {
        width: unset;
        font-size: 1.1rem;
    }
}