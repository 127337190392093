.banner-nav {
    position: absolute;
    bottom: 0.4rem;
    right: 0;
    width: 58%;
    min-width: 705px;
    box-sizing: border-box;
    padding: 1.7rem 1rem 1rem;
    border-top: 2px solid var(--primary-color-2);
    --indicator-position: 0;
    --multiplyer: 25%; // this gets adjusted by the BannerNav component when it mounts
    background-color: rgba(0,0,0, 0.15);

    &:before {
        content: "";
        position: absolute;
        top: -2px;
        left: calc(var(--indicator-position) * (var(--multiplyer) - 0.5rem));
        width: calc(var(--multiplyer) + 1rem);
        height: 2px;
        opacity: 1;
        border-top: 2px solid var(--primary-color-1);
        transition: left 0.125s ease-in-out;
    }
}

.mobile-nav-carousel {

    .flickity-viewport {
        padding-top: 0.75rem;
        border-top: 2px solid var(--primary-color-2);
    }
}

.banner-mobile-nav {
    display: none;
    position: absolute;
    bottom: 0.4rem;
    left: 0;
    width: 100%;

    .banner-nav__item--active {
        &:before {
            content: '';
            position: absolute;
            top: -0.75rem;
            left: 0;
            z-index: 100;
            width: 100%;
            height: 0;
            border-top: 2px solid var(--primary-color-1);
        }
    }
}

@media screen and (max-width: 720px) {
    .banner-nav {
        display: none;
    }

    .banner-mobile-nav {
        display: block;
    }
}

.banner-nav__list {
    display: flex;
}

.banner-nav__item {
    position: relative;
    flex: 0 1 var(--multiplyer);
    padding: 0 1rem 0 0.5rem;
    border-left: 2px solid var(--primary-color-1);  
}

.banner-nav__link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: transparent;
    color: var(--primary-color-5);
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;

    &:hover {
        .link__title,
        .link__subtitle {
            opacity: 1;
            transition: opacity 0.225s ease-in-out;
        }
    }
}

.link__title {
    font-size: 0.9rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-bottom: 0.3rem;
}

.link__subtitle {
    font-size: 0.75rem;
    font-weight: 300;
}

.link__title,
.link__subtitle {
    opacity: 0.6;
}

.banner-nav__item--active {
    .link__title,
    .link__subtitle {
        opacity: 1;
        transition: opacity 0.325s ease-out;
    }
}