.showcase {
    background-image: linear-gradient(135deg, rgba(76,76,76,1) 0%,rgba(89,89,89,1) 12%,rgba(102,102,102,1) 25%,rgba(71,71,71,1) 39%,rgba(44,44,44,1) 50%,rgba(0,0,0,1) 51%,rgba(17,17,17,1) 60%,rgba(43,43,43,1) 76%,rgba(28,28,28,1) 91%,rgba(19,19,19,1) 100%); 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
}

.showcase__description {
    text-align: center;
    line-height: 1.5;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.showcase__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem 1rem;
    list-style: none;
    margin: 2rem 0 0;
    padding: 0;
}

.showcase__item {
    flex: 0 1 calc(33% - 0.5rem);
    position: relative;

    .video-card__caption .text-link {
        color: #fff;
    }
}

.showcase-item__link {
    width: 100%;
}

.showcase .video-card__title {
    color: var(--primary-color-5);
    font-size: 1.22rem;
    line-height: 1.4;
    padding-right: 1rem;
    margin-bottom: 0;
}

@media screen and (max-width: 1020px) {
    .showcase__item {
        flex-basis: calc(50% - 0.9rem);
    }
}

@media screen and (max-width: 600px) {
    .showcase {
        &.page-section-padding {
            padding-bottom: 3rem;
            padding-top: 3rem;
        }
    }

    .showcase__list {
        display: block;
    }

    .showcase__item {
        margin-bottom: 2rem;
    }

    .showcase__description {
        width: 100%;
    }
}