.recent-news {
    --slide-width: calc(22.222222% - 1rem);
    --slide-padding: 1rem;
    background-image: url(https://85b46b791b5f3b2751f8-e82bfe2fb47c3e0e09486fab21221afc.ssl.cf5.rackcdn.com/scripps/snn_background-image_NEWS.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

@media  screen and (max-width: 1000px) {
    .recent-news {
        --slide-width: 28.57142%;
    }
}

@media  screen and (max-width: 700px) {
    .recent-news {
        --slide-width: 40%;
    }
}

@media  screen and (max-width: 501px) {
    .recent-news {
        --slide-width: 75%;
    }
}