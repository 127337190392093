.main-nav-btn {
    display: grid;
  gap: 0.35rem;
  grid-template-columns: 1fr 1fr 1fr;
  background: transparent;
  border: none;
  margin: 0; 
  padding: 0;
  transition: gap 0.225s ease-in-out, transform 0.225s ease-out, top 0.225s ease-in-out;
  cursor: pointer;
    position: absolute;
    top: 3rem;
    right: 10%;
    z-index: 200;

    &:hover {
        transform: scale(1.15, 1.15);

        .main-nav-btn__icon {
            fill: var(--primary-color-1);

            path {
                stroke: var(--primary-color-1);
            }
        }
    }
}

.main-nav-btn[data-state="active"] {
    gap: 0;

    .main-nav-btn__icon {
        background-color: var(--primary-color-1);
    }
}

.main-nav-btn__icon {
    width: 0.35rem;
    height: 0.35rem;
    background-color: #fff;
    transition: all 0.225s ease-in-out;
    transform-origin: top left;
    box-shadow: 1px 1px 2px rgba(0,0,0, 0.7);
  }
  
  .main-nav-btn[data-state="active"] .main-nav-btn__icon:nth-child(1), 
  .main-nav-btn[data-state="active"] .main-nav-btn__icon:nth-child(3),
  .main-nav-btn[data-state="active"] .main-nav-btn__icon:nth-child(5),
  .main-nav-btn[data-state="active"] .main-nav-btn__icon:nth-child(7),
  .main-nav-btn[data-state="active"] .main-nav-btn__icon:nth-child(9) {
      width: 0.65rem;
      height: 0.65rem;
      border-radius: 0;
      transform: rotate(45deg) translate(0.25rem, -0.25rem) scale(1.5);
  }
  
  .main-nav-btn[data-state="active"] .main-nav-btn__icon:nth-child(2), 
  .main-nav-btn[data-state="active"] .main-nav-btn__icon:nth-child(4),
  .main-nav-btn[data-state="active"] .main-nav-btn__icon:nth-child(6),
  .main-nav-btn[data-state="active"] .main-nav-btn__icon:nth-child(8){
      opacity: 0;
      width: 0;
      height: 0;
  }

@media screen and (max-width: 600px) {
    .main-nav-btn {
        top: 2rem;
    }

    .main-nav-btn[data-state="active"] .main-nav-btn__icon:nth-child(1), 
    .main-nav-btn[data-state="active"] .main-nav-btn__icon:nth-child(3),
    .main-nav-btn[data-state="active"] .main-nav-btn__icon:nth-child(5),
    .main-nav-btn[data-state="active"] .main-nav-btn__icon:nth-child(7),
    .main-nav-btn[data-state="active"] .main-nav-btn__icon:nth-child(9) {
        width: 0.4rem;
        height: 0.4rem;
    }
}

.menu-active {
    transition: transform 0.225s ease-in-out;

    .main-nav-btn__icon {
        filter: unset;
        fill: var(--primary-color-1);

        path {
            stroke: var(--primary-color-1);
        }
    }

    &:hover {
        transform: rotate(180deg);
    }
}