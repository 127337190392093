.resource-info {
    padding: 3rem 0 6.8rem;
    background-color: var(--primary-color-7);
}

.resource-info__content-wrapper {
    display: grid;
    grid-template-areas:
        "info-main info-side"
        "info-share info-side";
    grid-template-columns: 2fr 1fr;
    column-gap: 8rem;
    padding: 1rem 0;

    p {
        color: #000;
    }
}

.resource-info__main {
    grid-area: info-main;
}

.resource-info__side {
    grid-area: info-side;
    padding-top: 1.4rem;
}

.resource-info__contacts {
    display: flex;
    flex-wrap: wrap;
}

.resource-info__contact {
    position: relative;
    margin-right: 1rem;
    padding: 0 1rem;

    &:before {
        content: '||';
        position: absolute;
        top: -0.4rem;
        left: -0.75rem;
        color: var(--primary-color-4);
        font-size: 1.6rem;
    }

    &:first-child {
        padding-left: 0;

        &:before {
            content: unset;
        }
    }

    @media screen and (min-width: 850px) and (max-width: 1024px) {
        margin-right: 0.75rem;
        padding: 0 0.3rem;
        position: relative;
        font-size: 15px;
    }

    @media screen and (min-width: 601px) and (max-width: 849px) {
        padding: 0 1rem 1rem 0;

        &:before {
            content: unset;
        }

        &:last-child {
            padding-bottom: 1rem;
            border-bottom: 4px double var(--primary-color-2);
        }
    }
}

.resource-info__share {
    grid-area: info-share;
    display: flex;
    align-items: center;
    margin-top: 3rem;

    .social-links {
        padding: 0 0 0 1rem;
    }

    @media screen and (min-width: 601px) and (max-width: 849px) {
        flex-direction: column;
        align-items: flex-start;

        .social-links {
            padding: 1rem 0 0 0;
        }
    }

    .social-link {
        padding: 0.45em 0.55em 0.2em;
        background-color: transparent;
        border-width: 1px;
        margin-right: 1rem;

        > .social-link__icon {
            fill: var(--primary-color-1);
        }

        &:hover {
            background-color: var(--primary-color-1);

            > .social-link__icon {
                fill: var(--primary-color-5);
            }
        }
    }
}

.resource-info__share-title {
    text-transform: uppercase;
    color: #000;
    font-weight: 700;
    letter-spacing: 4px;
    font-size: 1rem;
}

@media screen and (max-width: 600px) {
    .resource-info {
        padding: 1rem 1.5rem 3rem;
    }

    .resource-info__content-wrapper {
        grid-template-columns: 1fr;
        grid-template-areas:
            "info-main"
            "info-side"
            "info-share";
        row-gap: 2rem;
    }

    .resource-info__contact {
        position: relative;
        margin-right: 1rem;
        padding: 0 1rem 1rem 0;

        &:before {
            content: unset;
        }

        &:last-child {
            padding-bottom: 1rem;
            /*border-bottom: 4px double var(--primary-color-2);*/
        }
    }

    .resource-info__share {
        margin-top: 0;
    }
}

